import {UserManager, WebStorageStateStore, User} from 'oidc-client';
import router from '@/router/router';
import urlJoin from 'url-join';
import Axios from 'axios';
import gammaStore from "@/store/gammaStore";
import navbarStore from "@/store/navbarStore";

function SmartSuiteSecurityPlugin() {
    this.url = window.location.origin;
    this.settings = {
        userStore: new WebStorageStateStore({store: window.localStorage}),
        authority: this.get_authority(),
        client_id: this.get_clientId(),
        client_secret: this.get_secret(),
        redirect_uri: this.get_redirectUri(),
        automaticSilentRenew: true,
        revokeAccessTokenOnSignout: true,
        //to force new token every minute
        //accessTokenExpiringNotificationTime: 3540,
        //to force new token every 30 seconds when tokens expire every 60 seconds
        //accessTokenExpiringNotificationTime: 30,
        response_type: 'code',
        scope: 'openid profile email roles offline_access segmentgrants modulegrants featuregrants eventgrants',
        post_logout_redirect_uri: this.get_logoutRedirectUri(),
        filterProtocolClaims: true,
        loadUserInfo: true
    };
    this.userManager = new UserManager(this.settings);
    this.userManager.events.addAccessTokenExpiring(() => {
        console.log("TOKEN EXPIRING");
        this.refreshToken();
    });
    this.userManager.events.addAccessTokenExpired(() => {
        //it should never get to the point where a token expires, but for whatever reason if it does this could
        //potentially catch it as well
        console.warn("TOKEN EXPIRED");
        this.refreshToken();
    });
}

SmartSuiteSecurityPlugin.prototype.get_authority = function() {
    return config.VUE_APP_SMARTSUITEAUTHORITY || process.env.VUE_APP_SMARTSUITEAUTHORITY;
}

SmartSuiteSecurityPlugin.prototype.get_secret = function() {
    return process.env.VUE_APP_SMARTSUITESECRET;
}

SmartSuiteSecurityPlugin.prototype.get_clientId = function() {
    return config.VUE_APP_SMARTSUITECLIENTID || process.env.VUE_APP_SMARTSUITECLIENTID;
    //return 'satellitedev';
}

SmartSuiteSecurityPlugin.prototype.get_redirectUri = function() {
    //return JSON.parse(localStorage.getItem("customFields")).rk_redirectUri || null;
    return urlJoin(this.url,  '/callback.html');
}

SmartSuiteSecurityPlugin.prototype.get_silentRedirectUri = function() {
    //return JSON.parse(localStorage.getItem("customFields")).rk_silentRedirectUri || null;
    return urlJoin(this.url, '/silent-renew.html');
}

SmartSuiteSecurityPlugin.prototype.get_logoutRedirectUri = function() {
    //return JSON.parse(localStorage.getItem("customFields")).rk_logoutRedirectUri || null;
    return  urlJoin(this.url, '/');
}

SmartSuiteSecurityPlugin.prototype.get_userSettingsUri = function() {
    return urlJoin(this.get_authority() + "/Identity/Account/Manage");
}

SmartSuiteSecurityPlugin.prototype.refreshToken = function() {
    // Use the signinSilent method to check if the token is valid
    this.userManager.signinSilent()
        .then(async user => {
            console.log('User re-logged in: ' + new Date().toLocaleString());
            // Call your methods here
            await gammaStore.dispatch("refreshHubs");
        })
        .catch(error => {
            // The token is invalid, refresh it
            console.error('Error refreshing token:', error);
        });
}

SmartSuiteSecurityPlugin.prototype.login = function() {
    this.clearUser();
    Axios.get(this.get_authority() + '/.well-known/openid-configuration')
        .then((response) => {
            if (response.status === 200) {
                return this.userManager.signinRedirect();
            } else {
                console.warn('Smart Suite Security Unreachable');
                //router.push('/');
            }
        })
        .catch((error) =>{
            console.error(error);
            //router.push('/');
        });
}

SmartSuiteSecurityPlugin.prototype.logout = function() {
    return this.userManager.signoutRedirect();
}

SmartSuiteSecurityPlugin.prototype.clearUser = function() {
    if (this.userManager !== undefined) {
        this.userManager.removeUser();
        this.userManager.clearStaleState();
    }
}

SmartSuiteSecurityPlugin.prototype.clearIncompleteState = function() {
    this.userManager.clearStaleState();
}

SmartSuiteSecurityPlugin.prototype.setCallbackUrl = function(to) {
    //TODO Move this to store
    localStorage.setItem('callback', window.origin + "/#" + to.fullPath);
}

SmartSuiteSecurityPlugin.prototype.requireAuth = function(to, from, next) {
    //async function proceed() {
        //if(this.userManager === undefined || this.userManager === null) {
        //    await make_userManager();
        //}
        Axios.get(this.get_authority() + '/.well-known/openid-configuration')
            .then(async (response) => {
                if (response.status === 200) {
                    //if (userManager !== undefined) {
                        let user = await this.userManager.getUser();
                        //if (user !== null && user.scope.includes("riscengine") && user.scope.includes("riscstorage")) {
                        if (user !== null) {
                            if (user.access_token !== null && user.expired === false) {
                                //let pathClaim = await navbarStore.dispatch('getNavClaim', to.path);
                                let nav = await navbarStore.dispatch('getNav', to.matched[to.matched.length-1].path);
                                if (nav !== undefined && user.profile.modulegrants && user.profile.modulegrants.includes(nav._id) || nav !== undefined && nav.global === true) {
                                    this.setCallbackUrl(to);
                                    next();
                                } else {
                                    next('/');
                                }
                            } else {
                                this.setCallbackUrl(to);
                                next(this.userManager.signinRedirect());
                            }
                        } else {
                            this.setCallbackUrl(to);
                            next(this.userManager.signinRedirect());
                        }
                    /*} else {
                        setCallbackUrl(to);
                        await make_userManager();
                        userManager.signinRedirect();
                    }*/
                } else {
                    console.warn('Smart Suite Security Unreachable')
                    this.clearUser();
                    next('/');
                }

            })
            .catch((error) => {
                console.error(error)
                this.clearUser();
                next('/');
            })
    //}
    //proceed();
}

SmartSuiteSecurityPlugin.prototype.gatherUserInfo = function() {
    return new Promise((resolve, reject) => {
        Axios({
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + this.getAccessToken()
            },
            data: {
                token: this.getAccessToken(),
                client_secret: this.get_secret(),
                client_id: this.get_clientId()
            },
            url: this.get_authority() + '/connect/userinfo'
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        });
    });
}

SmartSuiteSecurityPlugin.prototype.validateResource = function(resourceId) {
    return new Promise((resolve,reject) => {
        Axios({
            method: "POST",
            url: config.VUE_APP_SMARTSUITEAUTHORITY+'/resource/validate',
            headers: {
                'Content-Type': 'text/json',
                Authorization: 'Bearer ' + this.getAccessToken()
            },
            params: {
                resourceId: resourceId,
            }
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            resolve(false);
        });
    })
}

SmartSuiteSecurityPlugin.prototype.getValidResources = function(message) {
    let headers = {
        'Content-Type': 'text/json',
        Authorization: 'Bearer ' + this.getAccessToken()
    }
    let url = config.VUE_APP_SMARTSUITEAUTHORITY+'/resource/redaction'
    return new Promise((resolve, reject) => {
        Axios.post(url, message, { headers })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                console.error(error)
                reject('Uncaught (in promise)');
            });
    })
}

SmartSuiteSecurityPlugin.prototype.getUserInfo = function() {
    return new Promise((resolve, reject) => {
        this.userManager.getUser()
            .then(user => {
                resolve(user);
            })
            .catch(error => reject(error));
    });
}

SmartSuiteSecurityPlugin.prototype.getIdTokenAsync = function() {
    return new Promise((resolve, reject) => {
        this.userManager.getUser()
            .then(user => {
                resolve(user.id_token);
            })
            .catch(error => reject(error));
    })
}

SmartSuiteSecurityPlugin.prototype.getAccessToken = function() {
    let user = JSON.parse(localStorage.getItem(`oidc.user:${this.settings.authority}:${this.settings.client_id}`));
    return user === null ? null : user.access_token;
}


SmartSuiteSecurityPlugin.prototype.getIdToken = function() {
    let user = JSON.parse(localStorage.getItem(`oidc.user:${this.settings.authority}:${this.settings.client_id}`));
    return user === null ? null : user.id_token;
}

SmartSuiteSecurityPlugin.prototype.getUserManager = function() {
    return this.userManager;
}

SmartSuiteSecurityPlugin.prototype.getAccessTokenAsync = function() {
    return new Promise((resolve, reject) =>{
        this.userManager.getUser()
            .then(user => {
                resolve(user.access_token)
            })
            .catch(error => reject(error))
    });
}

export default SmartSuiteSecurityPlugin;