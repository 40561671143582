import Vue from 'vue';
import Vuex from 'vuex';
import PubSub from 'pubsub-js';
import constellations from '@/services/constellation';
import tenantStore from "@/store/tenantStore";
import gammaStore from "@/store/gammaStore";
import SmartSuiteDevice from "../shared/smartsuite_services/smartsuite_device";
import SmartSuiteMedia from "../shared/smartsuite_services/smartsuite_media";
import authStore from "@/store/authStore";
import SmartSuiteArchive from "@/shared/smartsuite_services/smartsuite_archive";

Vue.use(Vuex);

function handle_gamma_multisensor(camera) {
    let sources = [];
    camera.streams.forEach(stream => {
        if (stream.sourceToken !== null && !sources.includes(stream.sourceToken)) {
            sources.push(stream.sourceToken);
        }
    });
    return sources.length;
}

async function format_ssdevices(devices, commandChannel, tenantId, callback) {
    if (devices) {
        devices.map(async device => {
            if (device.deviceType === 1) {
                device._id = device.serialNumber;
                device.devicetype = (device.deviceType === 1 ? "camera" : "other");
                device.status = (device.detectionStatus === 0 ? "active" : "inactive");
                device.elasticband = {gamma: true};
                device.multisensor = handle_gamma_multisensor(device);
                device.commandChannel = commandChannel;
                device.edgeService = device.origin;
                return device;
            }
        });
        callback(devices.map(device => new SmartSuiteDevice(device)));
    }
}

let deviceStore = new Vuex.Store({
    state: {
        userName: undefined,
        devices: {},
        loginFailures: {},
        archivedDevices: {},
        cameras: {},
        audio: {},
        viewerList: {},
        currentCameras: [],
        currentPlaybackStream: [],
        deviceErrors: [],
        curGridPreset: null,
        quality: 0,
        streamOptions:  {
            bool: false,
            device: null,
            dataChannel: null,
            sourceToken: null,
        },
        edgeServices: {},
        edgeServiceListeners: [],
    },
    mutations: {
        updateDeviceErrors(state, payload) {
            state.deviceErrors[payload.tenantId] = payload.errors;
            PubSub.publish('deviceerrorsupdate-' + payload.tenantId, state.deviceErrors[payload.tenantId]);
        },
        updateTenantAudioDevices(state, payload) {
            if (state.audio[payload.tenantId] !== undefined) {
                payload.devices.map(device => {
                    let update = state.audio[payload.tenantId].findIndex(_ => _._id === device._id);
                    if (update !== -1) {
                        state.audio[payload.tenantId][update] = Object.assign(state.audio[payload.tenantId][update], device);
                    } else {
                        state.audio[payload.tenantId].push(device);
                    }
                });
            } else {
                state.audio[payload.tenantId] = payload.devices;
            }
            PubSub.publish('audiodevicesupdate-' + payload.tenantId, state.audio[payload.tenantId]);
        },
        updateTenantDevices(state, payload) {
            if (state.devices[payload.tenantId] !== undefined) {
                payload.devices.map(device => {
                    let update = state.devices[payload.tenantId].findIndex(_ => _.getDeviceId() === device.getDeviceId());
                    if (update !== -1) {
                        state.devices[payload.tenantId][update].device = Object.assign(state.devices[payload.tenantId][update].device, {});
                    } else {
                        state.devices[payload.tenantId].push(device);
                    }
                });
            } else {
                state.devices[payload.tenantId] = payload.devices;
            }
            PubSub.publish('devicesupdate-' + payload.tenantId, state.devices[payload.tenantId]);
        },
        updateTenantDevicesAll(state, payload) {  // Don't worry about this
            state.devices[payload.tenantId] = payload.devices;
            PubSub.publish('devicesupdate-' + payload.tenantId, state.devices[payload.tenantId]);
        },
        //TODO PubSub
        updateTenantDevice(state, payload) {
            if (state.devices[payload.tenantId] !== undefined) {
                let device = state.devices[payload.tenantId].find((_device, index) => {
                    if (_device._id === payload.device._id) {
                        state.devices[payload.tenantId][index] = {..._device, ...payload.device};
                        return _device;
                    }
                });
                if (device === undefined) {
                    state.devices[payload.tenantId].push(payload.device);
                }
            } else {
                state.devices[payload.tenantId] = [payload.device];
            }
        },
        updateTenantLoginFailures(state, payload) {
            if (state.loginFailures[payload.tenantId] !== undefined) {
                payload.loginFailures.map(device => {
                    let update = state.loginFailures[payload.tenantId].findIndex(_ => _.getDeviceId() === device.getDeviceId());
                    if (update !== -1) {
                        state.loginFailures[payload.tenantId][update] = Object.assign(state.loginFailures[payload.tenantId][update], device);
                    } else {
                        state.loginFailures[payload.tenantId].push(device);
                    }
                });
            } else {
                state.loginFailures[payload.tenantId] = payload.loginFailures;
            }
            PubSub.publish('loginfailuresupdate-' + payload.tenantId, state.loginFailures[payload.tenantId]);
        },
        //TODO PubSub
        addTenantDevice(state, payload) {
            if (state.devices[payload.tenantId] !== undefined) {
                state.devices[payload.tenantId].push(payload.device);
            } else {
                state.devices[payload.tenantId] = [payload.device];
            }
        },
        //TODO PubSub
        deleteTenantDevice(state, payload) {
            if (state.devices[payload.tenantId] !== undefined) {
                state.devices[payload.tenantId] = state.devices[payload.tenantId].filter(_device => _device._id !== payload.device);
            }
        },
        setQuality(state, payload) {
            state.quality = payload;
        },
        ADD_ACTIVE_CAMERA(state, payload) {
            if (payload.index === undefined) {
                state.currentCameras.push(payload.stream);
            } else {
                state.currentCameras.splice(payload.index, 0, payload.stream);
            }
        },
        REMOVE_ACTIVE_CAMERA(state, payload) {
            let deviceId = payload.deviceId ? payload.deviceId : payload.ssDevice.getDeviceId();
            let index = state.currentCameras.findIndex(_ => {
                return _.ssDevice.getDeviceId() === deviceId && _.sourceToken === payload.sourceToken &&
                    (_.ssDevice.isPanoramic() === true ? _.dataChannel === payload.dataChannel : true);
            });
            if (index !== -1) {
                state.currentCameras.splice(index, 1);
            }
        },
        CLEAR_ACTIVE_CAMERAS(state) {
            state.currentCameras = [];
        },
        ADD_SMARTSUITE_DEVICE(state, payload) {
              if (state.devices[payload.tenantId] !== undefined) {
                  state.devices[payload.tenantId].push(payload.device);
              } else {
                  state.devices[payload.tenantId] = [payload.device];
              }
        },
        ADD_SMARTSUITE_DEVICES(state, payload) {
            if (state.devices[payload.tenantId] !== undefined) {
                payload.devices.map(device => {
                    let index = state.devices[payload.tenantId].findIndex(_ => {
                        return _.getDeviceId() === device.getDeviceId();
                    });
                    if (index !== -1) {
                        state.devices[payload.tenantId][index].updateDevice(device.getDevice());
                    } else {
                        state.devices[payload.tenantId].push(device);
                        PubSub.publish('ssdevicesupdate-' + payload.tenantId, state.devices[payload.tenantId]);
                    }
                });
            } else {
                Vue.set(state.devices, payload.tenantId, payload.devices);
            }
            PubSub.publish('ssdevicesupdate-' + payload.tenantId, state.devices[payload.tenantId]);
        },
        CHANGE_DEVICE_INFO(state, payload) {
            //if the source tokens are different just combine the streams
            let index = state.devices[payload.tenantId].findIndex(device => {
                return device.getDeviceId() === payload.deviceSerial;
            });
            if (index !== -1) {
                if (state.devices[payload.tenantId][index].getSourceToken() === payload.sourceToken || state.devices[payload.tenantId][index].getSourceToken() === undefined) {
                    state.devices[payload.tenantId][index].updateDevice(payload);
                } else {
                    state.devices[payload.tenantId][index].addStreams(payload.streams);
                }
            }
        },
        UPDATE_SMARTSUITE_DEVICE(state, device) {
            let index = state.devices[device.getTenantId()].findIndex(device2 => {
                return device2.getDeviceId() === device.getDeviceId();
            });
            state.devices[device.getTenantId()][index].updateDevice(device.getDevice());
        },
        UPDATE_ARCHIVE_DEVICE(state, device) {
            state.archivedDevices[device.getDeviceId()] = device;
        },
        UPDATE_CUR_GRID_PRESET(state, preset) {
            state.curGridPreset = preset;
        },
        UPDATE_STREAM_OPTIONS(state, payload) {
            state.streamOptions.bool = payload.bool;
            state.streamOptions.device = payload.device;
            state.streamOptions.sourceToken = payload.sourceToken;
            state.streamOptions.dataChannel = payload.dataChannel;
        },
        CHANGE_DEVICE_QUALITY(state, payload) {
            state.currentCameras[payload.index].dataChannel = payload.dataChannel;
            state.currentCameras[payload.index].sourceToken = payload.sourceToken;
            state.currentCameras[payload.index].quality = payload.quality;
        },
        UPDATE_EDGE_SERVICES(state, payload) {
            //if an edge service has already been added to a tenants list
            if (state.edgeServices[payload._id] !== undefined) {
                //if the edge service isn't in the array already, add it
                if (state.edgeServices[payload._id].find(edgeService => edgeService === payload.edgeService) === undefined) {
                    state.edgeServices[payload._id].push(payload.edgeService);
                }
                PubSub.publish('edgeServicesUpdate', {edgeServices: state.edgeServices[payload._id], tenantId: payload._id});
            } else {
                state.edgeServices[payload._id] = [payload.edgeService];
                PubSub.publish('edgeServicesUpdate', {edgeServices: state.edgeServices[payload._id], tenantId: payload._id});
            }
        },
        ADD_EDGE_LISTENER(state, payload) {
            state.edgeServiceListeners.push(`${payload.tenantId}|${payload.edgeService}`);
        },
        REMOVE_EDGE_LISTENER(state, payload) {
            let index = state.edgeServiceListeners.findIndex(listener => listener === (`${payload.tenantId}|${payload.edgeService}`))
            if (index !== -1) {
                state.edgeServiceListeners.splice(index, 1);
            }
        }
    },
    actions: {
        async resetEdgeListeners({getters}) {
            let listeners = getters.getEdgeServiceListeners;
            let gamma = await gammaStore.dispatch('getMediahub', config.VUE_APP_MESSAGESERVER);
            if (gamma.isConnected() !== true && gamma.isConnecting() !== true) {
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/mediahub');
            }
            for (const listener of listeners) {
                let array = listener.split('|');
                await gamma.sendMessage('AddSourceListenerAsync', array[1]);
            }
        },
        async getDeviceErrors({commit, state}, tenantId) {
            if (state.deviceErrors[tenantId] === undefined) {
                await gammaStore.dispatch('getDevicehub', tenantId);
                await gammaStore.dispatch('setDevicehubListener', {_id: tenantId, event: 'DeviceErrors', callback: async (event) => {
                        commit('updateDeviceErrors', {
                            tenantId: tenantId,
                            errors: event
                        });
                    }
                });
                return [];
            } else {
                return state.deviceErrors[tenantId];
            }
        },
        async getDeviceViewerList({commit, state}, payload) {
            let userName;
            await authStore.dispatch("getUserName").then(response => {
                userName = response;
            });
            let result = payload.isWatching ? [userName] : []
            let tenantId = payload.tenantId;
            if (state.devices && state.devices[tenantId] && state.devices[tenantId].find(device => device.getDeviceId() === payload.deviceId)) {
                let device = state.devices[tenantId].find(device => device.getDeviceId() === payload.deviceId);
                if (Array.isArray(device.getStreams())) {
                    device.getStreams().forEach(stream => {
                        if (stream.userIds && Array.isArray(stream.userIds) && stream.userIds.length > 0
                            && (stream.sourceToken === payload.sourceToken || payload.sourceToken === undefined)) {
                            result = [...result, ...stream.userIds];
                        }
                    }, result);
                }
                return [...new Set(result)].map(_ => _ === userName ? _ + " (you)" : _);
            } else {
                return [userName + " (you)"];
            }
        },
        async getAudioDevices({commit, state}, tenantId) {
            if (state.audio[tenantId] === undefined) {
                let retrievedTenant = await tenantStore.dispatch('getTenant', tenantId);
                await gammaStore.dispatch('getMediahub', retrievedTenant);
                await gammaStore.dispatch('setMediahubListener', {
                    _id: retrievedTenant._id, event: 'hubannouncements', callback: async (event) => {
                        //event[0].devices.map(async device => {
                        let temp = event.devices.filter(_ => _.streams.findIndex(a => a.streamType === SmartSuiteArchive.streamTypes.Audio) !== -1);
                        temp.map(async device => {
                            let index = device.streams.findIndex(_ => _.streamType === SmartSuiteArchive.streamTypes.Audio);
                            if (device.streams && device.streams.length > 0 && index !== -1) {
                                device._id = device.serialNumber;
                                device.devicetype = (device.deviceType === 1 ? "camera" : "other");
                                device.status = (device.detectionStatus === 0 ? "active" : "inactive");
                                device.elasticband = {gamma: true};
                                device.stream = device.streams[index];
                                return device;
                            }
                        });
                        commit('updateTenantAudioDevices', {
                            tenantId: tenantId,
                            //devices: event[0].devices
                            devices: temp
                        });
                    }
                });
                return [];
                //}
            } else {
                return state.audio[tenantId];
            }
        },
        async getEdgeServices() {
            let gamma = await gammaStore.dispatch('getMediahub', config.VUE_APP_MESSAGESERVER);
            if (gamma.isConnected() !== true && gamma.isConnecting() !== true) {
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/mediahub');
            }
        },
        async getSmartSuiteDevicesByEdgeService({commit, state}, payload) {
            let gamma = await gammaStore.dispatch('getMediahub', config.VUE_APP_MESSAGESERVER);
            if (gamma.isConnected() !== true && gamma.isConnecting() !== true) {
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/mediahub');
            }
            //if the payload says it isnt connected and we double check that it isn't a listener yet
            if (payload.isConnected === true && state.edgeServiceListeners.find(listener => listener === (`${payload.tenantId}|${payload.edgeService}`)) === undefined) {
                commit('ADD_EDGE_LISTENER', {tenantId: payload.tenantId, edgeService: payload.edgeService});
                let event = await gamma.sendMessage('AddSourceListenerAsync', payload.edgeService);
                let userRole = await authStore.getters.getUserRole;
                if (userRole !== 'securityadmin' && userRole !== 'poweruser') {
                    let redactedEvent = await authStore.dispatch('getValidResources', event);
                    await format_ssdevices(redactedEvent.data.devices, redactedEvent.data.commandChannel, payload.tenantId, ssDevices => {
                        commit('ADD_SMARTSUITE_DEVICES', {
                            devices: ssDevices,
                            tenantId: payload.tenantId,
                            commandChannel: redactedEvent.data.commandChannel
                        });
                    });
                } else {
                    await format_ssdevices(event.devices, event.commandChannel, payload.tenantId, ssDevices => {
                        commit('ADD_SMARTSUITE_DEVICES', {
                            devices: ssDevices,
                            tenantId: payload.tenantId,
                            commandChannel: event.commandChannel
                        });
                    });
                }
                if (state.devices[payload.tenantId] === undefined) {
                    return [];
                } else {
                    return [].concat(...Object.values(state.devices[payload.tenantId]));
                }
            } else if (payload.isConnected === false && state.edgeServiceListeners.find(listener => listener === (`${payload.tenantId}|${payload.edgeService}`)) !== undefined) {
                commit('REMOVE_EDGE_LISTENER', {tenantId: payload.tenantId, edgeService: payload.edgeService});
                gamma.sendMessage('RemoveSourceListenerAsync', payload.edgeService);
            }
        },
        async clearSourceListeners({commit, state}) {
            state.edgeServiceListeners.forEach(listener => {
                let result = listener.split('|');
                commit('REMOVE_EDGE_LISTENER', {tenantId: result[0], edgeService: result[1]});
            });
        },
        async setMediahubListener({commit, state}, tenantId) {
            let gamma = await gammaStore.dispatch('getMediahub', config.VUE_APP_MESSAGESERVER);
            if (gamma.isConnected() !== true) {
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/mediahub');
            }
            //only make one if there isn't already a listener for that tenant open
            await gammaStore.dispatch('setMediahubListener', {event: 'mediahubannouncement', callback: async (event) => {
                //this returns only what the user has access to
                let userRole = await authStore.getters.getUserRole;
                if (userRole !== 'securityadmin' && userRole !== 'poweruser') {
                    let redactedEvent = await authStore.dispatch('getValidResources', event);
                    await format_ssdevices(redactedEvent.data.devices, redactedEvent.data.commandChannel, tenantId, ssDevices => {
                        commit('ADD_SMARTSUITE_DEVICES', {
                            devices: ssDevices,
                            tenantId: tenantId,
                            commandChannel: redactedEvent.data.commandChannel
                        });
                    });
                } else {
                    await format_ssdevices(event.devices, event.commandChannel, tenantId, ssDevices => {
                        commit('ADD_SMARTSUITE_DEVICES', {
                            devices: ssDevices,
                            tenantId: tenantId,
                            commandChannel: event.commandChannel
                        });
                    });
                }
            }
            });
            if (state.devices[tenantId] === undefined) {
                return [];
            } else {
                return [].concat(...Object.values(state.devices[tenantId]));
            }
        },
        //this one is the one that gets the devices to the video wall
        //TODO this method should no longer be used since migration to multitenant 05/10/2024 -RL
        async getSmartSuiteDevices({commit, state}, tenantId) {
            let gamma = await gammaStore.dispatch('getMediahub', config.VUE_APP_MESSAGESERVER);
            if (gamma.isConnected() !== true && gamma.isConnecting() !== true) {
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/mediahub');
            }
            state.edgeServices[tenantId].forEach(edgeService => {
                //if we do not find an existiing listener push it into the array
                if (state.edgeServiceListeners.find(listener => listener === (`${tenantId}|${edgeService}`)) === undefined) {
                    state.edgeServiceListeners.push(`${tenantId}|${edgeService}`);
                    gamma.sendMessage('AddSourceListenerAsync', edgeService);
                }
            }).catch(e => {
                console.error(e, state.edgeServices)
            });
            await gammaStore.dispatch('setMediahubListener', {
                _id: tenantId, event: 'mediahubannouncement', callback: async (event) => {
                    //this returns only what the user has access to
                    let userRole = await authStore.getters.getUserRole;
                    if (userRole !== 'securityadmin' && userRole !== 'poweruser') {
                        let redactedEvent = await authStore.dispatch('getValidResources', event);
                        await format_ssdevices(redactedEvent.data.devices, redactedEvent.data.commandChannel, tenantId, ssDevices => {
                            commit('ADD_SMARTSUITE_DEVICES', {
                                devices: ssDevices,
                                tenantId: tenantId,
                                commandChannel: redactedEvent.data.commandChannel
                            });
                        });
                    } else {
                        await format_ssdevices(event.devices, event.commandChannel, tenantId, ssDevices => {
                            commit('ADD_SMARTSUITE_DEVICES', {
                                devices: ssDevices,
                                tenantId: tenantId,
                                commandChannel: event.commandChannel
                            });
                        })
                    }
                }
            });
            if (state.devices[tenantId] === undefined) {
                return [];
            } else {
                return [].concat(...Object.values(state.devices[tenantId]));
            }
        },
        async getLoginFailures({commit, state}, tenantId) {
            await gammaStore.dispatch('getDevicehub', tenantId);
            await gammaStore.dispatch('setDevicehubListener', {
                    _id: tenantId, event: 'loginfailures', callback: async (event) => {
                        event.map(device => {
                            device._id = device.ipAddress;
                            device.devicetype = 'Unknown';
                            device.detectionStatus = -1;
                        });
                        let ssDevices = event.map(device => new SmartSuiteDevice(device));
                        commit('updateTenantLoginFailures', {
                            tenantId: tenantId,
                            loginFailures: ssDevices
                        });
                }
            });
            return [];
        },
        // Create device from passed in object
        async createDevice({commit, state}, payload) {
            let retrievedTenant = await tenantStore.dispatch('getTenant', payload.tenantId);
            let device = {};
            if (retrievedTenant.gamma === undefined) {
                device = await constellations.createDevice(retrievedTenant.url + ':' + retrievedTenant.port, payload.device);
                commit('addTenantDevice', {tenantId: payload.tenantId, device: device.data});
            } else {
                // Handling for Gamma create device
            }
            return state.devices[payload.tenantId].find(_device => _device._id === device.data._id);
        },
        // Retrieve a specific device based on ID
        async readDevice({commit, state}, payload) {
            let retrievedTenant = await tenantStore.dispatch('getTenant', payload.tenantId);
            let device = {};
            if (retrievedTenant && retrievedTenant.gamma === undefined) {
                device.data = state.devices[payload.tenantId].find(_device => _device._id === payload.device);
                if (device.data === undefined) {
                    device = await constellations.readDevice(retrievedTenant.url + ':' + retrievedTenant.port, payload.device);
                    commit('updateTenantDevice', {tenantId: payload.tenantId, device: device.data});
                }
            } else {
                // Handling for Gamma read device
            }
            return state.devices[payload.tenantId].find(_device => _device._id === device.data._id);
        },
        // Update a specific device based on ID
        async updateDevice({commit, state}, payload) {
            let ssMedia = new SmartSuiteMedia(payload.device);
            await ssMedia.connect();
            await ssMedia.updateDevice();
            return state.devices[payload.tenantId].find(_device => _device.getDeviceId() === payload.device.getDeviceId());
        },
        // Remove a specific device based on ID
        async deleteDevice({commit, state}, payload) {
            let retrievedTenant = await tenantStore.dispatch('getTenant', payload.tenantId);
            let device = {};
            if (retrievedTenant && retrievedTenant.gamma === undefined) {
                device = await constellations.deleteDevice(retrievedTenant.url + ':' + retrievedTenant.port, payload.device);
                commit('deleteTenantDevice', {tenantId: payload.tenantId, device: payload.device});
            } else {
                // Handling for Gamma delete device
            }
            return null;
        },
        getQuality({state}) {
            return state.quality;
        },
        checkForEdgeListener({state}, payload) {
            if (payload.edgeService !== undefined) {
                //check for a specific opened edge service
                return state.edgeServiceListeners.find(listener => listener === `${payload.tenantId}|${payload.edgeService}`) !== undefined;
            } else {
                //check for any open listeners on a tenant
                return state.edgeServiceListeners.includes(payload.tenantId);
            }
        },
        addActiveCamera({commit, state, getters}, payload) {
            let result = {
                index: payload.index,
                stream: {
                    dataChannel: payload.dataChannel,
                    sourceToken: payload.sourceToken,
                    quality: (payload.quality !== undefined ? payload.quality :
                        (payload.ssDevice.getDeviceQuality(payload.dataChannel) !== undefined ? payload.ssDevice.getDeviceQuality(payload.dataChannel) : state.quality))
                }
            };
            result.stream.ssDevice = state.devices[payload.ssDevice.getTenantId()].find(device => {
                return device.getDeviceId() === payload.ssDevice.getDeviceId();
            });
            commit('ADD_ACTIVE_CAMERA', result);
        },
        removeActiveCamera({commit, state}, payload) {
            commit('REMOVE_ACTIVE_CAMERA', payload);
        },
        clearActiveCameras({commit}) {
            commit('CLEAR_ACTIVE_CAMERAS');
        },
        getDevice({state}, payload) {
            //get a specific ssdevice knowing the tenant and device id
            if (state.devices[payload.tenantId] !== undefined) {
                let index = state.devices[payload.tenantId].findIndex(device => {
                    return device.device._id === payload.deviceId;
                });
                if (index !== -1) {
                    return state.devices[payload.tenantId][index];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        addSmartSuiteDevice({commit, state}, device) {
            commit('ADD_SMARTSUITE_DEVICE', device);
        },
        updateSmartSuiteDevice({commit, state}, device) {
            commit('UPDATE_SMARTSUITE_DEVICE', device);
        },
        updateArchiveDevice({commit, state}, ssdevice) {
            commit('UPDATE_ARCHIVE_DEVICE', ssdevice);
        },
        updateCurGridPreset({commit, state}, preset) {
            commit('UPDATE_CUR_GRID_PRESET', preset);
        },
        updateStreamOptions({commit, state}, payload) {
            commit('UPDATE_STREAM_OPTIONS', payload);
        },
        clearStreamOptions({commit}) {
            commit('UPDATE_STREAM_OPTIONS', {
                bool: false,
                device: null,
                dataChannel: null,
                sourceToken: null,
            });
        },
        changeDeviceInfo({commit}, payload) {
            commit('CHANGE_DEVICE_INFO', payload);
        },
        updateEdgeServices({commit, state}, payload) {
            commit('UPDATE_EDGE_SERVICES', payload);
        },
        async changeDeviceQuality({commit, state}, payload) {
            let index = state.currentCameras.findIndex(camera => camera.sourceToken === payload.sourceToken && camera.dataChannel === payload.dataChannel);
            Vue.set(payload, 'dataChannel', payload.ssDevice.getDataChannel(payload.sourceToken, payload.quality));
            Vue.set(payload, 'index', index);
            commit('CHANGE_DEVICE_QUALITY', payload);
        },
        getTenantEdgeServices({state}, tenantId) {
            return state.edgeServices[tenantId];
        }
    },
    getters: {
        getStreamOptions(state) {
            return state.streamOptions;
        },
        getAllDevices(state) {
            // Turn into an array
            return state.devices;
        },
        getQuality(state) {
            return state.quality;
        },
        getCurrentCameras(state) {
            return state.currentCameras;
        },
        getCurrentPlaybackStreams(state) {
            return state.currentPlaybackStream;
        },
        getDeviceStore(state) {
            return state;
        },
        getCurGridPreset(state) {
            return state.curGridPreset;
        },
        getEdgeServices(state) {
            return state.edgeServices;
        },
        getEdgeServiceListeners(state) {
            return state.edgeServiceListeners;
        }
    }
});

export default deviceStore;